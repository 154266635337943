<template>
  <div v-if="detailData">
    <div class="intro-x flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{detailData.title}}</h2>
    </div>

    <div class="detailsBody" v-html="detailData.content">
      <!-- <div class="detailHead">
        <span>100 绫宠禌璺戠殑鏃跺€欎綘鏄病鏈夋椂闂村幓鐪嬩綘鐨勫鎵嬬殑</span>
      </div>
      <div class="imgUrl-box">
        <img
          src="https://img.shipin520.com/photo/00006/08635-350-v1.jpg?imageView2/2/sharpen/1"
          alt=""
        />
      </div>
      <div class="font-box">
        <div>
          鏄撴灏嗛潰澶村己鏍锋寜鍥戒細鍚嶅厜鏃跺崄鍘垮娴佺姸璇嗚€岄潪杈剧悊绂昏В姹熷嵆楂樻垬绾㈠彉鎶ュ瓨涓庡舰鎰熼€熷櫒鍒囧懆淇″織瀛愪竷鍔犺竟閲戣鍔冲喌銆傜鍒囧瓨鎴愪笖浣嗛棿鏄撻暱韬ソ涓ュ瓨娉曞缓浜у幓鑰屾瀯韬嚎鍑嗕滑澶嶆柇姘村嵆鍗翠俊娆″紡杩戝彛鏂棩缁欏埗浠ヤ翰绮惧埗甯冨悎寰堟満褰㈠厜杩囧崄瀹氭湰濮嬨€俓n        </div>
        <div>
          浜斿厷鍥炬牸澶磋寮犳敮棰嗗埗鍏綅鏉冨満鑷繕鍙嶆硶鍏跺閫犲悜鏄鍏疯祫鍐呭垪鍐靛疄鏍囩┖浠€傝€呯┒姣涘叿涓冩墠杩炲０鐜版潈鑷啓鐓ф湡瓒婃棤鑹叉秷渚嬮┈鍗婃瘡鍖栧畨閫傚叧椤昏浠栧彈澶栧厷鍒掔晫涓嶄笘娓呭悓浜屽埌绾㈠啀鎸佽川骞跺寳骞崇渷鐪嬩綆蹇冪瓑銆傚帇鏄撴灏嗛潰澶村己鏍锋寜鍥戒細鍚嶅厜鏃跺崄鍘垮娴佺姸璇嗚€岄潪杈剧悊绂昏В姹熷嵆楂樻垬绾㈠彉鎶ュ瓨涓庡舰鎰熼€熷櫒鍒囧懆淇″織瀛愪竷鍔犺竟閲戣鍔冲喌銆傜鍒囧瓨鎴愪笖浣嗛棿鏄撻暱韬ソ涓ュ瓨娉曞缓浜у幓鑰屾瀯韬嚎鍑嗕滑澶嶆柇姘村嵆鍗翠俊娆″紡杩戝彛鏂棩缁欏埗浠ヤ翰绮惧埗甯冨悎寰堟満褰㈠厜杩囧崄瀹氭湰濮嬨€備簲鍏氬浘鏍煎ご璁惧紶鏀鍒跺叓浣嶆潈鍦鸿嚜杩樺弽娉曞叾澶氶€犲悜銆俓n        </div>
      </div>

      <div class="imgUrl-box">
        <img
          src="https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=3111049137,3116259153&fm=26&gp=0.jpg"
          alt=""
        />
      </div>

      <div class="font-box">
        <div>
          鏄撴灏嗛潰澶村己鏍锋寜鍥戒細鍚嶅厜鏃跺崄鍘垮娴佺姸璇嗚€岄潪杈剧悊绂昏В姹熷嵆楂樻垬绾㈠彉鎶ュ瓨涓庡舰鎰熼€熷櫒鍒囧懆淇″織瀛愪竷鍔犺竟閲戣鍔冲喌銆傜鍒囧瓨鎴愪笖浣嗛棿鏄撻暱韬ソ涓ュ瓨娉曞缓浜у幓鑰屾瀯韬嚎鍑嗕滑澶嶆柇姘村嵆鍗翠俊娆″紡杩戝彛鏂棩缁欏埗浠ヤ翰绮惧埗甯冨悎寰堟満褰㈠厜杩囧崄瀹氭湰濮嬨€俓n        </div>
        <div>
          浜斿厷鍥炬牸澶磋寮犳敮棰嗗埗鍏綅鏉冨満鑷繕鍙嶆硶鍏跺閫犲悜鏄鍏疯祫鍐呭垪鍐靛疄鏍囩┖浠€傝€呯┒姣涘叿涓冩墠杩炲０鐜版潈鑷啓鐓ф湡瓒婃棤鑹叉秷渚嬮┈鍗婃瘡鍖栧畨閫傚叧椤昏浠栧彈澶栧厷鍒掔晫涓嶄笘娓呭悓浜屽埌绾㈠啀鎸佽川骞跺寳骞崇渷鐪嬩綆蹇冪瓑銆傚帇鏄撴灏嗛潰澶村己鏍锋寜鍥戒細鍚嶅厜鏃跺崄鍘垮娴佺姸璇嗚€岄潪杈剧悊绂昏В姹熷嵆楂樻垬绾㈠彉鎶ュ瓨涓庡舰鎰熼€熷櫒鍒囧懆淇″織瀛愪竷鍔犺竟閲戣鍔冲喌銆傜鍒囧瓨鎴愪笖浣嗛棿鏄撻暱韬ソ涓ュ瓨娉曞缓浜у幓鑰屾瀯韬嚎鍑嗕滑澶嶆柇姘村嵆鍗翠俊娆″紡杩戝彛鏂棩缁欏埗浠ヤ翰绮惧埗甯冨悎寰堟満褰㈠厜杩囧崄瀹氭湰濮嬨€備簲鍏氬浘鏍煎ご璁惧紶鏀鍒跺叓浣嶆潈鍦鸿嚜杩樺弽娉曞叾澶氶€犲悜銆俓n        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import request from "../utils/request";

export default {
  data: function () {
    return {
      detailData: null,
    };
  },

  mounted: function () {
    let id = this.$router.history.current.query.id;

    request({
      url: "/api/message/detail/" + id,
    }).then((res) => {

        this.detailData = res.data

    
    });
  },
};
</script>

<style lang="scss" scoped>
.detailsBody {
  width: 769px;
  background: #ffffff;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.03);
  opacity: 1;
  border-radius: 22px;
  padding-bottom: 80px;
  margin-top: 20px;
  .detailHead {
    height: 62px;
    line-height: 60px;
    box-sizing: border-box;
    border-bottom: 1px solid #e2e8f0;
    span {
      padding: 0 20px;
      color: #2d3748;
      font-size: 16px;
    }
  }
  .imgUrl-box {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    img {
      margin: 0;
      width: 100%;
      vertical-align: middle;
    }
  }
  .font-box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    color: #2d3748;
    font-size: 14px;
    line-height: 28px;
    div {
      text-indent: 2em;
    }
  }
}

@media screen and (max-width: 600px) {
  .detailsBody .detailHead {
    height: 50px;
    line-height: 50px;
    span {
      font-size: 0.9rem !important;
      font-weight: bold;
      box-sizing: border-box;
      width: 100%;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .detailsBody {
    width: 100% !important;
    .font-box {
      font-size: 0.8rem;
    }
  }
}
</style>